<style lang="scss">
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: black !important;
}


.acc-link:has( .menu-item-active) .acc-btn-link {
/* background: rgba(188, 222, 251, 0.5) !important; */
background: #e9dfff  !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: black !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background: #e9dfff  !important;
}
.aside-menu .menu-nav .menu-item.menu-item-active {
  background: #e9dfff  !important;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link {
  /* background-color: #e4e6ef !important; */
  background-color: #e9dfff!important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: black !important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-icon {
  color: black !important;
}
#kt_aside_parent{
.menu-item:hover{
  /* background-color: #e4e6ef !important; */
  background-color: #e9dfff!important;
}
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #637099;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666f8d;
} 
}
#kt_aside_parent:hover{
  ::-webkit-scrollbar {
  width: 4px;
}
}
</style>
<template>
  <div id="kt_aside_parent">
    <!-- begin:: Aside -->
    <div
      class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      id="kt_aside"
      ref="kt_aside"
      @mouseover="mouseEnter"
      @mouseleave="mouseLeave"
    >
      <!-- begin:: Aside -->
      <KTBrand></KTBrand>
      <!-- end:: Aside -->

      <!-- begin:: Aside Menu -->
      <div
        style="border-right: 1px solid #fff2f2"
        class="aside-menu-wrapper flex-column-fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          ref="kt_aside_menu"
          id="kt_aside_menu"
          class="aside-menu"
          data-menu-vertical="1"
          data-menu-dropdown-timeout="500"
          v-bind:class="asideMenuClass"
        >
          <!-- example static menu here -->
          <div
            class="aside-menu scroll"
            style="max-height: 90vh; position: relative; overflow-y: auto !important;"

          >
            <KTMenu></KTMenu>
  </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from "vuex";
import KTBrand from "@/includes/brand/Brand.vue";

import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTMenu from "@/includes/aside/Menu.vue";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
    };
  },
  components: {
    KTBrand,
    KTMenu,
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
    });
  },
  methods: {
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      // check if the left aside menu is fixed
      // Assuming you have a reference to kt_aside_menu_wrapper element
const ktAsideMenuWrapper = document.getElementById("kt_aside_menu_wrapper");

// Add mouse enter event listener to kt_aside_menu_wrapper
ktAsideMenuWrapper.addEventListener("mouseenter", () => {
  // check if the left aside menu is fixed
  if (document.body.classList.contains("aside-fixed")) {
    if (this.outsideTm) {
      clearTimeout(this.outsideTm);
      this.outsideTm = null;
    }

    // if the left aside menu is minimized
    if (document.body.classList.contains("aside-minimize")) {
      document.body.classList.add("aside-minimize-hover");
      // show the left aside menu
      document.body.classList.remove("aside-minimize");
    }
  }
});

    //   if (document.body.classList.contains("aside-fixed")) {
    //     if(document.getElementById("kt_aside_menu_wrapper")){
    //     if (this.outsideTm) {
    //       clearTimeout(this.outsideTm);
    //       this.outsideTm = null;
    //     }
    //   }

    //     // if the left aside menu is minimized
    //     if (document.body.classList.contains("aside-minimize")) {
    //       if(document.getElementById("kt_aside_menu_wrapper")){
        
    //       document.body.classList.add("aside-minimize-hover");

    //       // show the left aside menu
    //       document.body.classList.remove("aside-minimize");
    //     }
    //   }
    //   }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      //  move leave ux looks okay so kept as of now
      
      if (document.body.classList.contains("aside-fixed")) {
        if(document.getElementById("kt_aside_menu_wrapper")){
        if (this.insideTm) {
          clearTimeout(this.insideTm);
          this.insideTm = null;
        }
      }

        // if the left aside menu is expand
        if (document.body.classList.contains("aside-minimize-hover")) {
          if(document.getElementById("kt_aside_menu_wrapper")){
          // hide back the left aside menu
          document.body.classList.remove("aside-minimize-hover");
          document.body.classList.add("aside-minimize");
        }
      }
    }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    //   /**
    //    * Get extra classes for menu based on the options
    //    */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
