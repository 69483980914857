var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[(_vm.currentUser.u.is_admin == 0)?_c('router-link',{attrs:{"to":{ name: 'client.dashboard', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Dashboard ")])])])]}}],null,false,1297296200)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{ name: 'admin.dashboard' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Dashboard ")])])])]}}],null,false,1297296200)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
      name: 'admin.serviceprovider.index'
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-list-3"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Service Provider")])])])]}}],null,false,1316182503)}):_vm._e(),(_vm.currentUser.u.is_admin == 0)?_c('router-link',{attrs:{"to":{
      name: 'client.appointment.index',
      params: { client_slug: _vm.client_slug }
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
       
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-list-3"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Appointment List")])])])]}}],null,false,3258166780)}):_vm._e(),(_vm.currentUser.u.is_client && !_vm.currentUser.c.is_mobile_fingerprinting)?_c('router-link',{attrs:{"to":{
      name: 'client.appointment.baseplan',
      params: { client_slug: _vm.client_slug, client: _vm.client }
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-time"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Base Plan")])])])]}}],null,false,2318079526)}):_vm._e(),(
      (_vm.currentUser.u.is_client && _vm.currentUser.c.is_mobile_fingerprinting) ||
        _vm.currentUser.u.is_service_provider
    )?_c('router-link',{attrs:{"to":{
      name: 'client.job.list',
      params: { client_slug: _vm.client_slug }
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          _vm.$route.name === 'client.job.form' &&'menu-item-active',
          _vm.$route.name === 'client.job.details' &&'menu-item-active',
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-network"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Mobile Service")])])])]}}],null,false,298498928)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'client.company', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        _vm.$route.name === 'client.company.create' &&'menu-item-active',
        _vm.$route.name === 'client.company.edit' &&'menu-item-active',
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-building menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Abbreviation")])])])]}}],null,false,3107150569)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'client.promocode', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          _vm.$route.name === 'client.promocode.create' &&'menu-item-active',
          _vm.$route.name === 'client.promocode.edit' &&'menu-item-active',
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-tag menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Promocode")])])])]}}],null,false,2421446427)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'client.service', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          _vm.$route.name === 'client.service.create' &&'menu-item-active',
          _vm.$route.name === 'client.service.edit' &&'menu-item-active',
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"flaticon-book menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Services")])])])]}}],null,false,1677010520)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'client.location', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
         _vm.$route.name === 'client.location.create' &&'menu-item-active',
          _vm.$route.name === 'client.location.edit' &&'menu-item-active',
          _vm.$route.name === 'client.location.users' &&'menu-item-active',
          _vm.$route.name === 'client.location.rate' &&'menu-item-active',
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"flaticon-map-location menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Locations")])])])]}}],null,false,1458649469)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'client.groups', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-object-group menu-icon"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Group")])])])]}}],null,false,103685419)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'client.featured', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-sort menu-icon"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Featured")])])])]}}],null,false,2789020185)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{ name: 'admin.serviceori' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-list menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("ORI")])])])]}}],null,false,681378213)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{ name: 'admin.servicecodes' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-list menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Service Code")])])])]}}],null,false,97132737)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.client.invoice',
            // params: { client_slug: client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-4",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon la la-file-pdf-o"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Invoice List")])])])]}}],null,false,3334863986)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
      name: 'admin.download.index'
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon la la-download"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Download")])])])]}}],null,false,113543283)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'client.setting.livescanversion',
            params: { client_slug:_vm.currentUser.u.is_admin?'admin': _vm.client_slug }}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon la la-list"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Workstation Version")])])])]}}],null,false,507051792)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'client.apikey', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
         _vm.$route.name === 'client.apikey.devices' &&'menu-item-active',
        
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-warehouse menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Workstation")])])])]}}],null,false,3443897311)}):_vm._e(),(_vm.currentUser.u.is_service_provider || _vm.currentUser.u.is_client)?_c('router-link',{attrs:{"to":{
      name: 'client.traininglinks',
      params: { client_slug: _vm.client_slug }
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"las la-play menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Training")])])])]}}],null,false,4206749092)}):_vm._e(),_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[(_vm.currentUser.u.is_service_provider || _vm.currentUser.u.is_admin || _vm.currentUser.u.is_client)?_c('div',{staticClass:"acc-link"},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],staticClass:"menu-item p-5 mb-1 acc-btn-link",attrs:{"no-body":"","block":""}},[_vm._m(0)])]),_c('b-collapse',{attrs:{"id":"accordion-1","accordion":"my-accordion","role":"tabpanel"}},[(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.appointment.service.report'
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-file-text-o menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Appointment Summary")])])])]}}],null,false,2732892407)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.enrollment.service.report'
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-file-text-o menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Enrollment Summary")])])])]}}],null,false,1318372156)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.report.location.wise.report',
            
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-file-text-o menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Service Report")])])])]}}],null,false,1069405863)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.report.company.wise.report',
            
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-building menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Company ")])])])]}}],null,false,3324423121)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.report.location.job.wise.report',
            
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-file-text-o menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Mobile Service ")])])])]}}],null,false,3232799465)}):_vm._e(),(_vm.currentUser.u.is_service_provider || _vm.currentUser.u.is_client)?_c('router-link',{attrs:{"to":{
            name: 'report.location.wise.report',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-file-text-o menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Service Report ")])])])]}}],null,false,1986364857)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{
            name: 'report.company.wise.report',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-building menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Company ")])])])]}}],null,false,2549587215)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{
            name: 'report.location.job.wise.report',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-file-text-o menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Mobile Service ")])])])]}}],null,false,2674989367)}):_vm._e()],1)],1):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('div',{staticClass:"mb-1 acc-link",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-7",modifiers:{"accordion-7":true}}],staticClass:"menu-item p-5 mb-1 acc-btn-link",attrs:{"no-body":"","block":""}},[_vm._m(1)])]),_c('b-collapse',{attrs:{"id":"accordion-7","accordion":"my-accordion","role":"tabpanel"}},[(_vm.currentUser.u.is_admin == 0)?_c('router-link',{attrs:{"to":{
            name: 'client.company.invoice',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon la la-file-pdf-o"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Company")])])])]}}],null,false,1494986141)}):_vm._e()],1)],1):_vm._e(),(_vm.currentUser.u.is_admin)?_c('div',{staticClass:"mb-1 acc-link",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-6",modifiers:{"accordion-6":true}}],staticClass:"menu-item p-5 mb-1 acc-btn-link",attrs:{"no-body":"","block":""}},[_vm._m(2)])]),_c('b-collapse',{attrs:{"id":"accordion-6","accordion":"my-accordion","role":"tabpanel"}},[(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.archivallist',
          
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users ml-2"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Archival List")])])])]}}],null,false,2818222785)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.archival.expiration',
           
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users ml-2"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Archival Exp")])])])]}}],null,false,3167774286)}):_vm._e()],1)],1):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('div',{staticClass:"mb-1 acc-link",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-6",modifiers:{"accordion-6":true}}],staticClass:"menu-item p-5 mb-1 acc-btn-link",attrs:{"no-body":"","block":""}},[_vm._m(3)])]),_c('b-collapse',{attrs:{"id":"accordion-6","accordion":"my-accordion","role":"tabpanel"}},[(_vm.currentUser.u.is_admin == 0)?_c('router-link',{attrs:{"to":{
            name: 'client.archivallist',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Archival List")])])])]}}],null,false,1581759391)}):_vm._e(),(_vm.currentUser.u.is_admin == 0)?_c('router-link',{attrs:{"to":{
            name: 'client.archival.expiration',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Archival Exp")])])])]}}],null,false,4281159728)}):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"mb-1 acc-link",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[(_vm.currentUser.u.is_client || _vm.currentUser.u.is_service_provider)?_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}],staticClass:"menu-item p-5 mb-1 acc-btn-link",attrs:{"block":""}},[_vm._m(4)]):_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}],staticClass:"menu-item p-5 mb-1",attrs:{"block":""}},[_vm._m(5)])]),_c('b-collapse',{attrs:{"id":"accordion-2","accordion":"my-accordion","role":"tabpanel"}},[(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{
            name: 'client.master.company',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
               _vm.$route.name === 'client.master.company.create' &&'menu-item-active',
          _vm.$route.name === 'client.master.company.edit' &&'menu-item-active',
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-building menu-icon ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Company ")])])])]}}],null,false,1286225673)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{
            name: 'client.setting.livescanversion',
            params: { client_slug:_vm.currentUser.u.is_admin?'admin': _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-envelope ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Livescan Version ")])])])]}}],null,false,3135683837)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{
            name: 'client.email.gen',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-email ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Templates")])])])]}}],null,false,2358833019)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{
            name: 'client.archival',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Archival")])])])]}}],null,false,258981500)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{
            name: 'client.whitelistemail',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
               _vm.$route.name === 'client.whitelist.create' &&'menu-item-active',
          _vm.$route.name === 'client.whitelist.edit' &&'menu-item-active',
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-email ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("WhiteList Email")])])])]}}],null,false,2372202761)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{ name: 'client.notes', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-notes ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Notes")])])])]}}],null,false,1504076244)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{
            name: 'client.setting.email.configure',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-envelope ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Email ")])])])]}}],null,false,3091926338)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{
            name: 'client.setting.payment',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-credit-card ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Payment")])])])]}}],null,false,1017350830)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{
            name: 'client.setting.map_n_sms_config',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-map ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Map & SMS Config")])])])]}}],null,false,857730817)}):_vm._e(),(_vm.currentUser.u.is_service_provider || _vm.currentUser.u.is_client)?_c('router-link',{attrs:{"to":{
            name: 'client.profile',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-profile-1 ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Profile")])])])]}}],null,false,1228903272)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{ name: 'admin.profile' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-profile-1 ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Profile")])])])]}}],null,false,1228903272)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'timer.setup' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-time ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Timer")])])])]}}],null,false,3327833222)}):_vm._e(),(_vm.currentUser.u.roles == 'admin' && _vm.currentUser.u.is_admin == 0)?_c('router-link',{attrs:{"to":{ name: 'client.user', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
               _vm.$route.name === 'client.user.create' &&'menu-item-active',
          _vm.$route.name === 'client.user.edit' &&'menu-item-active',
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"flaticon-user menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("User")])])])]}}],null,false,3722501612)}):_vm._e(),(_vm.currentUser.u.is_admin == 1 && _vm.currentUser.u.roles == 'admin')?_c('router-link',{attrs:{"to":{ name: 'admin.user' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                 _vm.$route.name === 'admin.user.create' &&'menu-item-active',
          _vm.$route.name === 'admin.user.edit' &&'menu-item-active',
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"flaticon-user menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("User")])])])]}}],null,false,3095391820)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.deporder-fdle-configure'
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-envelope ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Submission ")])])])]}}],null,false,57390470)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{
            name: 'admin.setup.traininglink'
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon las la-play ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Training Link ")])])])]}}],null,false,3751464151)}):_vm._e(),(
            _vm.currentUser.u.roles == 'admin' &&
              _vm.currentUser.u.is_service_provider
          )?_c('router-link',{attrs:{"to":{ name: 'client.roles', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Roles")])])])]}}],null,false,839310769)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{ name: 'admin.email.configure' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-profile-1 ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Email Setting")])])])]}}],null,false,227829305)}):_vm._e(),(_vm.currentUser.u.is_admin == 1)?_c('router-link',{attrs:{"to":{ name: 'client.map_config' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-profile-1 ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v("Map Config")])])])]}}],null,false,3434965685)}):_vm._e()],1)],1),(
        _vm.currentUser.u.is_service_provider && _vm.currentUser.c.show_store_forward
      )?_c('div',{staticClass:"mb-1 acc-link",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-3",modifiers:{"accordion-3":true}}],staticClass:"menu-item p-5 mb-1 acc-btn-link",attrs:{"no-body":"","block":""}},[_vm._m(6)])]),_c('b-collapse',{attrs:{"id":"accordion-3","accordion":"my-accordion","role":"tabpanel"}},[(
            _vm.currentUser.u.is_service_provider == 1 &&
              _vm.currentUser.c.show_store_forward
          )?_c('router-link',{attrs:{"to":{
            name: 'client.storeforward',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-list-3"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Enrollment List")])])])]}}],null,false,701513488)}):_vm._e()],1)],1):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('div',{staticClass:"mb-1 acc-link",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-4",modifiers:{"accordion-4":true}}],staticClass:"menu-item p-5 mb-1 acc-btn-link",attrs:{"no-body":"","block":""}},[_vm._m(7)])]),_c('b-collapse',{attrs:{"id":"accordion-4","accordion":"my-accordion","role":"tabpanel"}},[(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{ name: 'log.payment', params: { client_slug: _vm.client_slug } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-notes"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Payments")])])])]}}],null,false,2234152384)}):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('router-link',{attrs:{"to":{
            name: 'log.apikeylogs',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-notes"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Workstation")])])])]}}],null,false,2204685266)}):_vm._e()],1)],1):_vm._e(),(_vm.currentUser.u.is_service_provider)?_c('div',{staticClass:"mb-1 acc-link",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-5",modifiers:{"accordion-5":true}}],staticClass:"menu-item p-5 mb-1 acc-btn-link",attrs:{"no-body":"","block":""}},[_vm._m(8)])]),_c('b-collapse',{attrs:{"id":"accordion-5","accordion":"my-accordion","role":"tabpanel"}},[(_vm.currentUser.u.is_service_provider || _vm.currentUser.u.is_client)?_c('router-link',{attrs:{"to":{
            name: 'client.analytical',
            params: { client_slug: _vm.client_slug }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"la la-file-text-o menu-icon"}),_c('span',{staticClass:"menu-text ml-6",staticStyle:{"color":"black"}},[_vm._v("Analytics")])])])]}}],null,false,772485244)}):_vm._e()],1)],1):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2"}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Reports ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Invoice ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Archivals ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Archivals ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Settings ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Setup ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Store Forward ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Logs ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-6"},[_vm._v(" Analytics ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
}]

export { render, staticRenderFns }