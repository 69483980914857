<style lang="scss">
.card-body {
  padding: 5px;
}
</style>
<template>
  <div
    style="position: fixed; bottom: 0; width: calc(100vw); z-index: 9999"
    id="kt_copyright_footer"
  >
    <div class="card">
      <div class="card-body">
        Copyright &copy; 2022 Total Biometric Solutions LLC. All rights
        reserved.
      </div>
      <!-- <div class="card-body" v-else>
        {{ copyright }}
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
