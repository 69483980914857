<style lang="scss" scoped>
.badge {
  margin-left: 5%;
}
// .collapse {
//   padding-left: 1rem !important;
// }

.collapsed  .when-opened {
  display: none !important;
}

:not(.collapsed) .when-closed {
  display:none !important
}
.not-collapsed  .when-closed {
  display: none !important;
}
:not(.not-collapsed) .when-opened {
  display:none !important
}
.collapsed  .when-closed {
  display: block !important;
}
.not-collapsed  .when-opened {
  display: block !important;
}

// .collapsed > .when-opened,
// :not(.collapsed) > .when-closed {
//   display: block;
// }
</style>
<template>
  <ul class="menu-nav">
    <router-link
      v-if="currentUser.u.is_admin == 0"
      :to="{ name: 'client.dashboard', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text" style="color: black"
            >Dashboard
            <!-- <span class="badge badge-pill badge-success">Beta</span> -->
          </span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="currentUser.u.is_admin == 1"
      :to="{ name: 'admin.dashboard' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text" style="color: black"
            >Dashboard
            <!-- <span class="badge badge-pill badge-success">Beta</span> -->
          </span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      :to="{ name: 'client.applicants' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a
                :href="href"
                class="menu-link"
                style="color:black;"
                @click="navigate">
          <i class="menu-icon flaticon-users-1"></i>
          <span class="menu-text">Applicants</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      :to="{
        name: 'admin.serviceprovider.index'
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_admin == 1"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list-3"></i>
          <span class="menu-text" style="color: black">Service Provider</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{
        name: 'client.appointment.index',
        params: { client_slug: client_slug }
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_admin == 0"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
         
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list-3"></i>
          <span class="menu-text" style="color: black">Appointment List</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{
        name: 'client.appointment.baseplan',
        params: { client_slug: client_slug, client: client }
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_client && !currentUser.c.is_mobile_fingerprinting"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-time"></i>
          <span class="menu-text" style="color: black">Base Plan</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{
        name: 'client.job.list',
        params: { client_slug: client_slug }
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        (currentUser.u.is_client && currentUser.c.is_mobile_fingerprinting) ||
          currentUser.u.is_service_provider
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
            $route.name === 'client.job.form' &&'menu-item-active',
            $route.name === 'client.job.details' &&'menu-item-active',
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-network"></i>
          <span class="menu-text" style="color: black">Mobile Service</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      :to="{
        name: 'client.appointment.schedule',
        params: { client_slug: client_slug }
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_client"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a
                :href="href"
                class="menu-link"
                style="color:black;"
                @click="navigate">
          <i class="menu-icon flaticon2-schedule"></i>
          <span class="menu-text">Schedule</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      :to="{ name: 'client.company', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_service_provider"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          $route.name === 'client.company.create' &&'menu-item-active',
          $route.name === 'client.company.edit' &&'menu-item-active',
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-time"></i> -->
          <i class="la la-building menu-icon" aria-hidden="true"></i>
          <span class="menu-text" style="color: black">Abbreviation</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'client.promocode', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_service_provider"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
            $route.name === 'client.promocode.create' &&'menu-item-active',
            $route.name === 'client.promocode.edit' &&'menu-item-active',
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-time"></i> -->
          <i class="la la-tag menu-icon" aria-hidden="true"></i>
          <span class="menu-text" style="color: black">Promocode</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'client.service', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_service_provider"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
            $route.name === 'client.service.create' &&'menu-item-active',
            $route.name === 'client.service.edit' &&'menu-item-active',
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-time"></i> -->
          <i class="flaticon-book menu-icon" aria-hidden="true"></i>
          <span class="menu-text" style="color: black">Services</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'client.location', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_service_provider"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
           $route.name === 'client.location.create' &&'menu-item-active',
            $route.name === 'client.location.edit' &&'menu-item-active',
            $route.name === 'client.location.users' &&'menu-item-active',
            $route.name === 'client.location.rate' &&'menu-item-active',
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="flaticon-map-location menu-icon" aria-hidden="true"></i>
          <!-- <i class="fa fa-leanpub menu-icon"></i> -->

          <span class="menu-text" style="color: black">Locations</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="currentUser.u.is_service_provider"
      :to="{ name: 'client.groups', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="la la-object-group menu-icon"></i>
          <span class="menu-text" style="color: black">Group</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="currentUser.u.is_service_provider"
      :to="{ name: 'client.featured', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="la la-sort menu-icon"></i>
          <span class="menu-text" style="color: black">Featured</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'admin.serviceori' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_admin == 1"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-time"></i> -->
          <i class="la la-list menu-icon" aria-hidden="true"></i>
          <span class="menu-text" style="color: black">ORI</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'admin.servicecodes' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_admin == 1"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-time"></i> -->
          <i class="la la-list menu-icon" aria-hidden="true"></i>
          <span class="menu-text" style="color: black">Service Code</span>
        </a>
      </li>
    </router-link>
    <router-link
            v-if="currentUser.u.is_admin == 1"
            :to="{
              name: 'admin.client.invoice',
              // params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-4"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon la la-file-pdf-o"></i>
                <span class="menu-text " style="color: black">Invoice List</span>
              </a>
            </li>
          </router-link>
    <router-link
      :to="{
        name: 'admin.download.index'
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_admin == 1"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon la la-download"></i>
          <span class="menu-text" style="color: black">Download</span>
        </a>
      </li>
    </router-link>
    <router-link
    :to="{
              name: 'client.setting.livescanversion',
              params: { client_slug:currentUser.u.is_admin?'admin': client_slug }}"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_admin == 1"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon la la-list"></i>
          <span class="menu-text" style="color: black">Workstation Version</span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="{ name: 'client.apikey', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_service_provider"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
           $route.name === 'client.apikey.devices' &&'menu-item-active',
          
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-time"></i> -->
          <i class="la la-warehouse menu-icon" aria-hidden="true"></i>
          <span class="menu-text" style="color: black">Workstation</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{
        name: 'client.traininglinks',
        params: { client_slug: client_slug }
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentUser.u.is_service_provider || currentUser.u.is_client"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-time"></i> -->
          <i class="las la-play menu-icon" aria-hidden="true"></i>
          <span class="menu-text" style="color: black">Training</span>
        </a>
      </li>
    </router-link>

    <!-- <li
      class="menu-section"
      v-if="currentUser.u.is_service_provider || currentUser.u.is_client"
    >
      <h4 class="menu-text">User</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li> -->

    <div class="accordion" role="tablist">
      <div v-if="currentUser.u.is_service_provider || currentUser.u.is_admin || currentUser.u.is_client" class="acc-link">
        <div header-tag="header" role="tab">
          <div
            class="menu-item p-5 mb-1 acc-btn-link"
            no-body
            
            block
            v-b-toggle.accordion-1
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2"></i>
              <span class="menu-text ml-6"> Reports </span>

              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse
          id="accordion-1"
          accordion="my-accordion"
          role="tabpanel"
        >
          <!-- <router-link
            :to="{
              name: 'admin.enrollment.ori.report'
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_admin == 1"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon2-time"></i>
                <i class="la la-file-text-o menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-6">Florida Service </span>
              </a>
            </li>
          </router-link> -->
          <router-link
            :to="{
              name: 'admin.appointment.service.report'
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_admin == 1"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="la la-file-text-o menu-icon ml-2" aria-hidden="true"></i>
                <span class="menu-text  ml-6">Appointment Summary</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'admin.enrollment.service.report'
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_admin == 1"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="la la-file-text-o menu-icon  ml-2" aria-hidden="true"></i>
                <span class="menu-text ml-6 ">Enrollment Summary</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'admin.report.location.wise.report',
              
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_admin == 1"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="la la-file-text-o menu-icon  ml-2" aria-hidden="true"></i>
                <span class="menu-text ml-6">Service Report</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'admin.report.company.wise.report',
              
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_admin == 1"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="la la-building menu-icon ml-2" aria-hidden="true"></i>
                <span class="menu-text ml-6">Company </span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'admin.report.location.job.wise.report',
              
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_admin == 1"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="la la-file-text-o menu-icon ml-2" aria-hidden="true"></i>
                <span class="menu-text ml-6">Mobile Service </span>
              </a>
            </li>
          </router-link>
          <!-- <router-link
            :to="{
              name: 'client.enrollment.ori.report',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_service_provider"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon2-time"></i>
                <i class="la la-file-text-o menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-6">Florida Service </span>
              </a>
            </li>
          </router-link> -->
          <router-link
            :to="{
              name: 'report.location.wise.report',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_service_provider || currentUser.u.is_client"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="la la-file-text-o menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-6">Service Report </span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'report.company.wise.report',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_service_provider"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="la la-building menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-6">Company </span>
              </a>
            </li>
          </router-link>

          <router-link
            :to="{
              name: 'report.location.job.wise.report',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_service_provider"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="la la-file-text-o menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-6">Mobile Service </span>
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>
      <div no-body class="mb-1 acc-link" v-if="currentUser.u.is_service_provider">
        <div header-tag="header" role="tab">
          <div
            no-body
            class="menu-item p-5 mb-1 acc-btn-link"
            
            block
            v-b-toggle.accordion-7
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-6"> Invoice </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse
          id="accordion-7"
          accordion="my-accordion"
          role="tabpanel"
        >
          <router-link
            v-if="currentUser.u.is_admin == 0"
            :to="{
              name: 'client.company.invoice',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon la la-file-pdf-o"></i>
                <span class="menu-text ml-6" style="color: black">Company</span>
              </a>
            </li>
          </router-link>
          <!-- <router-link
            v-if="currentUser.u.is_admin == 0"
            :to="{
              name: 'client.client.invoice',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon la la-file-pdf-o"></i>
                <span class="menu-text ml-6" style="color: black">Invoice List</span>
              </a>
            </li>
          </router-link> -->
        </b-collapse>
      </div>
      <div no-body class="mb-1 acc-link" v-if="currentUser.u.is_admin">
        <div header-tag="header" role="tab">
          <div
            no-body
            class="menu-item p-5 mb-1 acc-btn-link"
            
            block
            v-b-toggle.accordion-6
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-6"> Archivals </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse
          id="accordion-6"
          accordion="my-accordion"
          role="tabpanel"
        >
          <router-link
            v-if="currentUser.u.is_admin == 1"
            :to="{
              name: 'admin.archivallist',
            
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-users ml-2"></i>
                <span class="menu-text ml-6" style="color: black"
                  >Archival List</span
                >
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_admin == 1"
            :to="{
              name: 'admin.archival.expiration',
             
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-users ml-2"></i>
                <span class="menu-text ml-6" style="color: black"
                  >Archival Exp</span
                >
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>
      <div no-body class="mb-1 acc-link" v-if="currentUser.u.is_service_provider">
        <div header-tag="header" role="tab">
          <div
            no-body
            class="menu-item p-5 mb-1 acc-btn-link"
            
            block
            v-b-toggle.accordion-6
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-6"> Archivals </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse
          id="accordion-6"
          accordion="my-accordion"
          role="tabpanel"
        >
          <router-link
            v-if="currentUser.u.is_admin == 0"
            :to="{
              name: 'client.archivallist',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-users"></i>
                <span class="menu-text ml-6" style="color: black"
                  >Archival List</span
                >
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_admin == 0"
            :to="{
              name: 'client.archival.expiration',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-users"></i>
                <span class="menu-text ml-6" style="color: black"
                  >Archival Exp</span
                >
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>

      <div no-body class="mb-1 acc-link">
        <div header-tag="header" role="tab">
          <div
            class="menu-item p-5 mb-1 acc-btn-link"
            
            block
            v-b-toggle.accordion-2
            v-if="currentUser.u.is_client || currentUser.u.is_service_provider"
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-6"> Settings </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
          <div
            class="menu-item p-5 mb-1"
            
            block
            v-b-toggle.accordion-2
            v-else
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-6"> Setup </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <router-link
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            :to="{
              name: 'client.master.company',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                 $route.name === 'client.master.company.create' &&'menu-item-active',
            $route.name === 'client.master.company.edit' &&'menu-item-active',
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="la la-building menu-icon ml-2"></i>
                <span class="menu-text ml-6">Company </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            :to="{
              name: 'client.setting.livescanversion',
              params: { client_slug:currentUser.u.is_admin?'admin': client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon fa fa-envelope ml-2"></i>
                <span class="menu-text ml-6">Livescan Version </span>
              </a>
            </li>
          </router-link>
      
          <!-- <router-link
            :to="{
              name: 'client.setting.defaultlocationrate',
              params: { client_slug: client_slug }
            }"
            v-if="currentUser.u.is_service_provider"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon fa-regular fa-money-check"></i>
                <span class="menu-text ml-6">Default Florida Rate</span>
              </a>
            </li>
          </router-link> -->
          <!-- <router-link
            :to="{
              name: 'client.setting.defaultrate',
              params: { client_slug: client_slug }
            }"
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon fa-regular fa-money-check"></i>
                <span class="menu-text ml-6">Default Rate</span>
              </a>
            </li>
          </router-link> -->
          <router-link
            :to="{
              name: 'client.email.gen',
              params: { client_slug: client_slug }
            }"
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-email ml-2"></i>
                <span class="menu-text ml-6">Templates</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            :to="{
              name: 'client.archival',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-users ml-2"></i>
                <span class="menu-text ml-6">Archival</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'client.whitelistemail',
              params: { client_slug: client_slug }
            }"
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                 $route.name === 'client.whitelist.create' &&'menu-item-active',
            $route.name === 'client.whitelist.edit' &&'menu-item-active',
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-email ml-2"></i>
                <span class="menu-text ml-6">WhiteList Email</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            :to="{ name: 'client.notes', params: { client_slug: client_slug } }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-notes ml-2"></i>
                <span class="menu-text ml-6">Notes</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            :to="{
              name: 'client.setting.email.configure',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon fa fa-envelope ml-2"></i>
                <span class="menu-text ml-6">Email </span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            :to="{
              name: 'client.setting.payment',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon fa fa-credit-card ml-2"></i>
                <span class="menu-text ml-6">Payment</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            :to="{
              name: 'client.setting.map_n_sms_config',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon fa fa-map ml-2"></i>
                <span class="menu-text ml-6">Map & SMS Config</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="currentUser.u.is_service_provider || currentUser.u.is_client"
            :to="{
              name: 'client.profile',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-profile-1 ml-2"></i>
                <span class="menu-text ml-6">Profile</span>
              </a>
            </li>
          </router-link>
          <!-- <li class="menu-section" v-if="currentUser.u.is_admin == 1">
            <h4 class="menu-text">Profile</h4>
            <i class="menu-icon flaticon-more-v2"></i>
          </li> -->
          <router-link
            v-if="currentUser.u.is_admin == 1"
            :to="{ name: 'admin.profile' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-profile-1 ml-2"></i>
                <span class="menu-text ml-6">Profile</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_service_provider"
            :to="{ name: 'timer.setup' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-time ml-2"></i>
                <span class="menu-text ml-6">Timer</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{ name: 'client.user', params: { client_slug: client_slug } }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.roles == 'admin' && currentUser.u.is_admin == 0"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                 $route.name === 'client.user.create' &&'menu-item-active',
            $route.name === 'client.user.edit' &&'menu-item-active',
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="flaticon-user menu-icon ml-2" aria-hidden="true"></i>
                <span class="menu-text ml-6">User</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{ name: 'admin.user' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.u.is_admin == 1 && currentUser.u.roles == 'admin'"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                   $route.name === 'admin.user.create' &&'menu-item-active',
            $route.name === 'admin.user.edit' &&'menu-item-active',
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="flaticon-user menu-icon ml-2" aria-hidden="true"></i>
                <span class="menu-text ml-6">User</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_admin == 1"
            :to="{
              name: 'admin.deporder-fdle-configure'
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon fa fa-envelope ml-2"></i>
                <span class="menu-text ml-6">Submission </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_admin == 1"
            :to="{
              name: 'admin.setup.traininglink'
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon las la-play ml-2"></i>
                <span class="menu-text ml-6">Training Link </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="
              currentUser.u.roles == 'admin' &&
                currentUser.u.is_service_provider
            "
            :to="{ name: 'client.roles', params: { client_slug: client_slug } }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-users ml-2"></i>
                <span class="menu-text ml-6">Roles</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_admin == 1"
            :to="{ name: 'admin.email.configure' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-profile-1 ml-2"></i>
                <span class="menu-text ml-6">Email Setting</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="currentUser.u.is_admin == 1"
            :to="{ name: 'client.map_config' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon flaticon-profile-1 ml-2"></i>
                <span class="menu-text ml-6">Map Config</span>
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>
      <div
        no-body
        class="mb-1 acc-link"
        v-if="
          currentUser.u.is_service_provider && currentUser.c.show_store_forward
        "
      >
        <div header-tag="header" role="tab">
          <div
            no-body
            class="menu-item p-5 mb-1 acc-btn-link"
            
            block
            v-b-toggle.accordion-3
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-6"> Store Forward </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse
          id="accordion-3"
          accordion="my-accordion"
          role="tabpanel"
        >
          <router-link
            :to="{
              name: 'client.storeforward',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              currentUser.u.is_service_provider == 1 &&
                currentUser.c.show_store_forward
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link p-3 ml-5" @click="navigate">
                <i class="menu-icon flaticon-list-3"></i>
                <span class="menu-text ml-6" style="color: black"
                  >Enrollment List</span
                >
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>
      <div no-body class="mb-1 acc-link" v-if="currentUser.u.is_service_provider">
        <div header-tag="header" role="tab">
          <div
            no-body
            class="menu-item p-5 mb-1 acc-btn-link"
            
            block
            v-b-toggle.accordion-4
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-6"> Logs </span>

              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse
          id="accordion-4"
          accordion="my-accordion"
          role="tabpanel"
        >
          <router-link
            v-if="currentUser.u.is_service_provider"
            :to="{ name: 'log.payment', params: { client_slug: client_slug } }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link p-3 ml-5" @click="navigate">
                <i class="menu-icon flaticon-notes"></i>
                <span class="menu-text ml-6" style="color: black"
                  >Payments</span
                >
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_service_provider"
            :to="{
              name: 'log.apikeylogs',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link p-3 ml-5" @click="navigate">
                <i class="menu-icon flaticon-notes"></i>
                <span class="menu-text ml-6" style="color: black"
                  >Workstation</span
                >
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>
      <div no-body class="mb-1 acc-link" v-if="currentUser.u.is_service_provider">
        <div header-tag="header" role="tab">
          <div
            no-body
            class="menu-item p-5 mb-1 acc-btn-link"
            
            block
            v-b-toggle.accordion-5
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-6"> Analytics </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse
          id="accordion-5"
          accordion="my-accordion"
          role="tabpanel"
        >
          <router-link
            v-if="currentUser.u.is_service_provider || currentUser.u.is_client"
            :to="{
              name: 'client.analytical',
              params: { client_slug: client_slug }
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link p-3 ml-5" @click="navigate">
                <i class="la la-file-text-o menu-icon"></i>
                <span class="menu-text ml-6" style="color: black"
                  >Analytics</span
                >
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>
      
    </div>

    <!-- <li class="menu-section" v-if="currentUser.u.is_service_provider">
      <h4 class="menu-text">Logs</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      v-if="currentUser.u.is_service_provider"
      :to="{ name: 'log.payment', params: { client_slug: client_slug } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-notes"></i>
          <span class="menu-text" style="color:black;">Payments</span>
        </a>
      </li>
    </router-link> -->

    <!-- <router-link
      :to="{
        name: 'client.integrations.index',
        params: { client_slug: client_slug }
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a
                :href="href"
                class="menu-link"
                style="color:black;"
                @click="navigate">
          <i class="menu-icon fa fa-cogs"></i>
          <span class="menu-text">Integrations</span>
        </a>
      </li>
    </router-link> -->
  </ul>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import lodash from "lodash";
export default {
  name: "KTMenu",
  data() {
    return {
      show: true,
      client_slug: "",
      client: "",
      year: moment(String(new Date())).format("YYYY"),
      month: moment(String(new Date())).format("MM"),
      permissions: {
        you: ["access_to_own_attendance_records", "access_to_own_leaves_list"],
        company: [
          "access_to_all_employees_profile_data",
          "access_to_assign_shift",
          "access_to_all_leaves_list",
          "access_to_all_employees_attendance_records"
        ],
        payroll: ["access_to_salary_heads"],
        settings: ["access_to_settings"]
      }
    };
  },

  computed: {
    ...mapGetters(["getUserPermission", "currentUser"])
  },
  toggleNavbar() {
    this.show = !this.show;
  },

  created() {
    this.client_slug = this.$route.params.client_slug;
    this.client = this.currentUser.u.is_client ? "c" : "sp";
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    checkPermissionForMenu(permission, permissions) {
      var flag = false;
      lodash.filter(permissions, function(o) {
        if (o == permission) {
          flag = true;
          return flag;
        }
        return flag;
      });
      return flag;
    },
    checkPermissionForMenuHeader(cPermissions, permissions) {
      let result = permissions.filter(o1 => cPermissions.some(o2 => o1 === o2));
      return result.length > 0;
    }
  }
};
</script>
