<style lang="scss">
.img-logo {
  width: 65px;
  position: fixed;
  top: 2%;
  left: 100px;
}
</style>
<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="brand-tools" v-if="allowMinimize">
      <button
        class="brand-toggle btn btn-sm px-0"
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
        @click="toggle"
      >
        <i class="fa fa-bars" style="font-size:20px;color:#000"></i>
      </button>
    </div>
    <div
      class="brand-logo"
      style="
    margin-left: 40px;
    padding-top:0px ;
"
    >
      <router-link :to="{ name: 'client.dashboard' }">
        <div style="height: 43px; width: 120px; padding: 2px 0px; overflow: hidden;">
  <img
    alt="Logo"
    v-if="currentUser.u.is_admin == 0"
    :src="currentUser.c.image == null ? currentUser.c.logo : currentUser.c.image"
    style="height: 100%; width: 100%; object-fit: contain;"
  />
</div>

    
      </router-link>
    </div>

    <!-- <div> <h5>{{currentUser.c.name}}</h5></div> -->
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTBrand",
  data() {
    return {
      toggle_on: true
    };
  },
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    var add = document.getElementById("kt_header");
    add.classList.add("kt_header_toggle");
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
    toggle() {
      var toggle = !this.toggle_on;
      this.toggle_on = toggle;
      if (toggle) {
        var add = document.getElementById("kt_header");
        add.classList.add("kt_header_toggle");
      } else {
        var remove = document.getElementById("kt_header");
        remove.classList.remove("kt_header_toggle");
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUser"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    }
  }
};
</script>
<style lang="scss">
#kt_brand {
  .active {
    // margin-left: -21% !important;
    margin-left: 110px;
  }
}
.aside-minimize-hover {
  #kt_aside {
    #kt_aside_toggle {
      margin-left: -3% !important;
    }
  }
  #kt_header {
    margin-left: 264px !important;
  }
}
</style>
